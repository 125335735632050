.home {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 70px;
    align-items: center;
    margin-bottom: 185px;
    padding-top: 165px;
}

@media only screen and (max-width: 1024px) {
    .home {
        padding-top: 0;
    }
}

.home section {
    background: white;
    width: calc(100% - 160px);
}

.seccion {
    height: 100%;
    display: flex;
    justify-content: center;
    /*grid-template-columns: repeat(12, 1fr);*/
    /*gap:40px*/
}

@media only screen and (min-width: 1441px) {
    .texto-container,
    .image-container {
        width: 600px;
    }

    .home section {
        height: 600px;
    }
}

@media only screen and (max-width: 1440px) {
    .seccion {
        flex-direction: column;
        align-items: center;
    }

    .seccion.left .image-container {
        /*width: auto;*/
        order: 1;
    }

    .texto-container,
    .image-container {
        padding-right: 0 !important;
        padding-left: 0 !important;

    }
}

.seccion .image-container .tooltip {
    display: inline-block;
    transform: translate(-25px, -25px);
    /*transform: translate(-50px);*/
    direction: rtl;
    transition: .5s ease-in-out;
    width: 0;
    z-index: 100;
}

.seccion img:hover + .tooltip {
    transform: translate(-25px, 35px);
}

.tooltip {
    font-weight: bold;
    font-size: 21px;
    color: var(--main-text-color);
    margin-bottom: 20px;
}

.texto-container .texto .title {
    text-align: center;
}

.seccion .image-container {
    display: inline-block;
    overflow: hidden;
    text-align: center;
    /*height: 100%;*/
    /*width: 100%;*/
}

.image-container .carrousel {
    position: relative;
}

.carrousel img {
    position: absolute;
}

.carrousel .pos-1 {
    right: 0;
}

.carrousel .pos-2 {
    right: 20px;
}

.seccion .image {

    height: 100%;
    max-width: 100%;
    transition: transform 0.5s;
}

.seccion .image:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

.seccion .texto {
    text-align: justify;
    letter-spacing: 1.5px;
    line-height: 1.6;
}

.seccion .texto.cabecera {
    /*font-weight: bold;*/
    text-align: center;
    font-size: 21px;
    color: var(--main-text-color);
}

.seccion > .cabecera {
    padding-bottom: 25px;
}

.texto {
    max-width: 800px;
}

/*.seccion.left .texto {*/
/*    !*padding-top: 20px;*!*/
/*    !*padding-bottom: 20px;*!*/
/*    padding-left: 50px;*/
/*}*/
/*.seccion.right .texto {*/
/*    !*padding-top: 50px;*!*/
/*    !*padding-bottom: 50px;*!*/
/*    padding-right: 50px;*/
/*}*/

/*.texto-container{*/
/*    width: 500px;*/
/*}*/

.seccion.left .texto-container,
.seccion.right .image-container {
    justify-content: flex-start;
    padding-left: 30px;
    /*width: 600px;*/
}

.seccion.right .texto-container,
.seccion.left .image-container {
    justify-content: flex-end;
    padding-right: 30px;
    /*width: 600px;*/
}


.seccion .item-2 {
    grid-column-end: span 2;
}

.seccion .item-5 {
    grid-column-end: span 5;
}

.seccion .item-7 {
    grid-column-end: span 7;
}

.seccion .item-4 {
    grid-column-end: span 4;
}

.seccion .item-6 {
    grid-column-end: span 6;
    display: flex;
}

.seccion .item-8 {
    grid-column-end: span 8;
}

.seccion .item-10 {
    grid-column-end: span 10;
}

.seccion .item-12 {
    grid-column-end: span 12;
}

.seccion.gap {
    gap: 70px;
}
