/*.weare {*/
/*    border-top: 130px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    gap: 170px;*/
/*}*/

.product-container {
    display: flex;
    width: calc(100% - 320px);
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-top: 150px;
}


@media only screen and (max-width: 820px) {
    .product-container {
        width: auto;
    }
}

@media only screen and (min-width: 1280px) {
    .product-container {
        height: 975px;
    }
}


.container {
    /*padding-top: 135px;*/
    /*width: calc(100% - 320px);*/
}

.product-container .title {
    font-weight: bold;
    font-size: 40px;
    color: var(--main-text-color);

}

.product-container .text {
    font-size: 21px;
    color: var(--main-text-color);
    text-align: center;
    letter-spacing: 1.5px;
    line-height: 1.6;
}
.product-container .images {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.product-container .images img {
    width: 500px;
}

.product-container .paddingContainer {
    padding: 0 100px;
}


@media only screen and (max-width: 1024px) {
    .product-container {
        margin-top: 0;
    }
    .product-container .paddingContainer {
        padding: 0;
    }
}
