.copyright,
.footer {
    background: rgb(239, 239, 239);
    align-items: center;
    display: flex;
    color: #888;
}

@media only screen and (max-width: 600px) {
    .copyright,
    .footer {
        flex-direction: column;
    }
}

.footer {
    font-size: 15px;
    min-height: 15vh;
    /*position: fixed;*/
    left: 0;
    bottom: 0;
    width: 100%;
    /*text-align: center;*/
    padding-top: 10px;
}

.copyright {
    background: rgb(255, 255, 255);
    justify-content: center;
    padding: 10px;
}

.footer .direccion {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 25px;
}

.footer .direccion,
.footer .social-links,
.footer .logo {
    flex:1;
}

.footer .social-links .socialmedia{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.socialmedia img {
    width: 30px;
    filter: invert(12%) sepia(13%) saturate(19%) hue-rotate(322deg) brightness(105%) contrast(87%);
}