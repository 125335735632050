.topes {
    width: 160px;
}
.menu {
    width: 100%;
    height: 130px;
    background: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 200;
}

.hamburg {
    position: absolute;
    top: 13px;
    right: 13px;
}

@media only screen and (max-width: 1024px) {
    .menu {
        flex-direction: column;
        height: 49px;
        transition: height 1s;
    }

    .menu .links {
        flex-direction: column;
        gap: 20px !important;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .full {
        height: 300px;
    }
}

@media only screen and (min-width: 1024px) {
    .menu {
        position: fixed;
    }
    .hamburg {
        display: none;
    }
}

.menu .left {
    display: flex;
    flex-direction: row;
    gap: 25px;
    min-width: 195px;
}

.footer .logo,
.menu .logo {
    /*height: 50px;*/
    flex: 1;
}

.menu .logo:hover {
    cursor: pointer;
}
.footer .logo .albe,
.footer .logo .deco,
.menu .logo .albe,
.menu .logo .deco {
    font-size: 35px;
}

.menu .links {
    font-size: 18px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex: 2;
    gap: 35px;
    color: #2f2f2f;
    font-weight: bold;
}

.links a:after {
    display: block;
    content: "";
    background: black;
    position: absolute;
    height: 2.5px;
    width: 0;
    transition: width .5s ease-in-out;
}

.links a:hover:after {
    width: 50px;
}

.links a:nth-child(1):after {
    background: #D0D0D0FF;
}
.links a:nth-child(2):after {
    background: #D0D0D0FF;
}
.links a:nth-child(3):after {
    background: #D0D0D0FF;
}
.links a:nth-child(4):after {
    background: #D0D0D0FF;
}
.links a:nth-child(5):after {
    background: #D0D0D0FF	;
}


.menu .lang-selector {
    flex: 1;
    color: black;
    display: flex;
    justify-content: flex-end;
}

select {
    appearance: none;
    background: url(../../Assets/down-svgrepo-com.svg) no-repeat transparent right;
    border: none;
    padding: 0 1em 0 1em;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    display: grid;
    grid-template-areas: "select";
    align-items: center;
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    color: black;
}