

.container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;
}

.container .item {
    display: flex;
    width: 300px;
    height: 300px;
    background-color: #D7D7D7FF;
    border: 20px white solid;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.item .image {
    margin-top: 50px;
    width: 70px;
}

.item .text{
    text-align: center;
    letter-spacing: 1.2px;
    line-height: 1.4;
    width: 200px;

}

.item p {
    font-size: 23px;
}