@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@900&display=swap');

:root {
  --main-text-color: #8c8c8c;
  --main-bg-color: #dddddd;
}

body {
  background: #ffffff;
  font-family: 'Roboto', sans-serif;
  color: #2d2d2d;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@media only screen and (max-width: 600px) {
  body {
    font-size: 11px;
  }
}

.albe {
  font-family: 'Playfair Display', serif;
}

.albe,
.deco {
  font-size: 15vw;
}

.under .albe,
.under .deco {
  font-size: 10vw;
}

a {
  text-decoration: none;
  color: #8c8c8c;
}


.front {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: calc(-135% + 30px);
  z-index: 1;
}

.front img {
  /*height: 100vh;*/
  width: 100%;
}

.weare,
.contact,
.about,
.clients {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 70px;
  align-items: center;
  margin-bottom: 185px;
  padding-top: 165px;
}


@media only screen and (max-width: 1024px) {
  .weare,
  .contact,
  .about,
  .clients {
    padding-top: 0;
  }
}

