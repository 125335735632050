.contact-form {
    background: rgb(136, 136, 136);
    padding: 50px;
    width: 60%;
    min-width: 500px;
    max-width: 700px;
}


.form-container {
    display: flex;
    flex-direction: column;
    width: auto;
    gap: 10px;
}

.form-container .actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-container>div>input,textarea {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 0;
    height: 45px;
    background-color: #FFF;
    border: 1px solid #E3DDD7;
}

textarea {
    min-height: 140px;
    font-family: 'Roboto', sans-serif;
    color: #2d2d2d;
}

button {
    padding: 8px 18px;
    border-style: solid;
    cursor: pointer;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    font-weight: 800;
    min-height: 39px;
    line-height: 23px;
    letter-spacing: 0.03em;
    white-space: nowrap;
    font-size: 15px;
    text-transform: uppercase;
    /*border: 1px solid;*/
    /*color: #ffffff;*/
    /*background-color: #111;*/
    /*border-color: #111;*/
    transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    background-color: #242322;
    color: #FFF;
    border-color: #242322;
}

button.disabled {
    background-color: #5b5b5b;
    color: #FFF;
    border-color: #242322;
}

.contactHeader {
    height: 350px;
    width: 100%;
    background: rgb(239, 239, 239);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.contactHeader .p {
    font-size: 50px;
    font-weight: bold;
}

.contactHeader div {
    font-size: 18px;
}

input.error {
    background: lightsalmon !important;
}

